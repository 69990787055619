function ChatRoomInfo ({lang, roomStatus, editContact}) {
    return (
      <div className="chat-room-info">
        <div
          className="center-bar sp-col"
          style={{ flexDirection: "column", paddingTop: "12px", paddingBottom: "12px" }}
        >
          <div
            className="sp-logo -super-extra-lg"
            style={{
              backgroundImage: `url(${
                roomStatus.img_profile ||
                "../public/img/default_profile_img.png"
              })`,
              marginBottom: 2,
            }}
          ></div>
            <p
              className="sp-text -ellipsis"
              style={{width: "100%", textAlign: "center", fontSize: 14, marginBottom: 2 }}
            >
              {roomStatus.firstname
                ? `${roomStatus.firstname} ${roomStatus.lastname || ""}`
                : roomStatus.displayname || "-"}
            </p>
          <a
            className="sp-text-help -hover-link -ellipsis"
            style={{ fontSize: 12, marginBottom: 2, textAlign: "center" }}
            href={`mailto:${roomStatus?.email}`}
          >
            {roomStatus?.email}
          </a>
          <a
            className="sp-text-help -hover-link"
            style={{ fontSize: 12, marginBottom: 2 }}
            href={`tel:${roomStatus?.tel}`}
          >
            {roomStatus?.tel}
          </a>
          <div
            className="sp-link -hover-underline"
            style={{ fontSize: 12 }}
            onClick={editContact}
          >
            {lang.__edit}
          </div>
        </div>
      </div>
    );
}